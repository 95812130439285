/*!
Theme Name: CMH - Theme
Theme URI: http://sallyiscreative.com
Description: Theme developed specifically for Community Memorial Hospital
Author: Sally Rauschenfels / David Horn
Author URI: http://sallyiscreative.com
Version: 1.0
*/

.chromeframe {
  position: absolute;
  top: 0;
}
@themes: "_";

@import "@{themes}/css/grid.less";
@import "@{themes}/css/elements.less";
@import "@{themes}/css/reset.less";
@import "@{themes}/css/nivo-slider.less";
@import "@{themes}/css/slidebars.less";
@import "@{themes}js/colorbox.css";

@total-width: 100%;

@blue: #214398;
@turquoise: #00acca;
@blue-light: #d7eff1;
@orange: #f59431;
@grey: #3f3d2e;
@green: #8ec549;
@green-light: #e5f0d6;
@purple: #aa288d;
@green-dark: #017575;

html {
  height: 100%;
  scroll-behavior: smooth;
}

.sb-toggle-left {
  position: absolute;
  z-index: 300;
  right: 5px;
  top: 8px;
  cursor: pointer;
}

body {
  font: 13px Lato, Helvetica, Arial, Verdana, sans-serif;
  min-height: 100%;
  height: auto;
  color: #333333;
  position: relative; /* Required for static Slidebars to function properly. */
}

a {
  color: @blue;
  .transition(0.3s);
}
a:hover {
  color: @blue;
}

div.XXX {
  display: none;
}
div.divider {
  height: 2px;
  border-bottom: 1px solid fadeout(@grey, 70%);
}

/* And here begins the WordPress fun.
-------------------------------------------------------------------------------*/

h1,
h2,
h3,
h4 {
  font-family: Alike, Georgia, Times, serif;
  font-weight: normal;
}

h2,
h3,
h2.subHead a {
  color: @blue;
}

img {
  max-width: 100%;
}

#wrapper {
  width: 1024px;
  margin: 20px auto;
}

header {
  padding: 16px 0 10px;
  background: #ffffff;
  h1 {
    margin: 0;
    font-size: 2.6rem;
  }
  #headerContact {
    text-align: right;
    p {
      font-size: 18px;
      line-height: 25px;
      a {
        color: #333333;
      }
      a:hover {
        color: @blue;
      }
      i {
        display: inline-block;
        margin: 0 0 0 10px;
        font-size: 22px;
      }
    }
  }
}

nav#topNav {
  background: @blue-light;
  height: 30px;
  overflow: hidden;
  #header_nav {
    padding: 0;
    li {
      display: inline-block;
      background: @blue-light;
      float: left;
      margin: 0 7px;
      font-size: 12px;
      a {
        color: @blue;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        height: 30px;
        padding: 0 10px;
        border-bottom: 2px solid @blue-light;
      }
      a:hover {
        border-bottom: 2px solid @blue;
      }
    }

    li:first-child,
    li:last-child {
      margin: 0;
    }

    li.portal a {
      background: @orange;
      color: #ffffff;
      border-bottom: 2px solid @orange;
    }
    li.bill a {
      background: @turquoise;
      color: #ffffff;
      border-bottom: 2px solid @turquoise;
    }

    li.book,
    li.covid {
      margin-right: 0px;
      margin-left: 0px;
      a {
        background: @purple;
        color: #ffffff;
        border-bottom: 2px solid @purple;
      }
    }

    li.covid {
      a {
        background: @blue;
        border-bottom: 2px solid @blue;
      }
    }
    li.covid a:hover,
    li.portal a:hover,
    li.book a:hover,
    li.bill a:hover {
      border-bottom: 2px solid @blue;
    }
  }
}

nav#mainNav {
  background: @blue;
  margin-bottom: 25px;
  #main_nav {
    padding: 0;
    li {
      display: inline-block;
      background: @blue;
      float: left;
      margin-right: 20px;
      font-size: 14px;
      text-transform: uppercase;
      a {
        color: #ffffff;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        height: 50px;
        padding: 0 11px;
        font-weight: 400;
      }
      a:hover {
        color: @turquoise;
      }
    }
    li.current_page_item,
    li.current-page-ancestor {
      a {
        background: @turquoise;
        color: #ffffff;
      }
    }
  }
}

#mainNav ul {
  *zoom: 1;
  list-style: none;
  margin: 0;
  padding: 0;
}
#mainNav ul:before,
#mainNav ul:after {
  content: "";
  display: table;
}
#mainNav ul:after {
  clear: both;
}
#mainNav ul > li {
  float: left;
  position: relative;
}
#mainNav a {
  display: block;
  padding: 10px 20px;
  line-height: 1.2em;
}
#mainNav a:hover {
  text-decoration: none;
}

#mainNav li ul li {
  width: 300px;
  margin-right: 0px !important;
}
#mainNav li ul a {
  text-align: left !important;
  width: 100%;
  display: inline-block !important;
  padding: 15px 0 10px 20px !important;
}
#mainNav li ul a:hover {
  background: rgba(0, 0, 0, 0.2);
}

#mainNav li.menu-item-has-children a:first-child::after {
  // content: "<i class=\"fa fa-angle-double-down\" aria-hidden=\"true\"></i>";
  content: "\00BB";
  padding-left: 10px;
  font-size: 16px;
}

#mainNav li.menu-item-has-children ul.sub-menu a:first-child::after {
  content: "";
}

#mainNav li ul {
  position: absolute;
  left: 0;
  top: 50px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: 200ms ease;
  -moz-transition: 200ms ease;
  -o-transition: 200ms ease;
  transition: 200ms ease;
}
#mainNav #main_nav > li > ul li > .sub-menu {
  top: 0;
  left: 100%;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: 200ms ease;
  -moz-transition: 200ms ease;
  -o-transition: 200ms ease;
  transition: 200ms ease;
}
#mainNav #main_nav > li > ul li.menu-item-has-children > a {
  padding-right: 20px !important;
}
#mainNav #main_nav > li > ul li.menu-item-has-children > a:after {
  content: "\00BB";
  float: right;
}
#mainNav #main_nav > li > ul li:hover > .sub-menu,
#mainNav ul > li:hover ul {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
}

/* MAP	*/

#footerMap,
#contactMap {
  width: 100%;
  height: 300px;
  margin: 0px;
}

#contactMap {
  height: 400px;
}

#footerMap img,
#contactMap img {
  max-width: none;
}

.button {
  display: inline-block;
  color: #ffffff;
  text-align: center;
  padding: 4px 22px;
  background: @blue;
  margin-top: 10px;
}

.button.orange {
  background: @orange;
}

.button.expanded {
  padding: 10px 22px;
  margin-right: 10px;
}

.button.expanded:hover {
  color: #ffffff;
  background: @orange;
}

h2.subHead span {
  font-size: 0.75rem;
}

.hapcats {
  display: none;
}

.sidebarHappenings,
.textBlock {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid @grey;
}

.sectionHead {
  h2 {
    margin: 0;
    text-align: center;
    background: @grey;
    color: @blue-light;
    padding: 10px;
    font-size: 2.4rem;
    position: relative;
    padding-bottom: 10px;
    i {
      display: inline-block;
      padding-right: 20px;
      vertical-align: middle;
      font-size: 2.5rem;
    }
    span {
      text-transform: uppercase;
      display: block;
      font-size: 1rem;
      position: absolute;
      bottom: -20px;
      right: 160px;
    }
  }

  h2.purple {
    background: @purple;
  }

  .blue {
    background: @blue;
  }

  h2.servicesHead {
    color: #ffffff;
    a {
      color: #ffffff;
    }
  }

  div.servicesHead {
    padding: 22px 0 26px 50px;
    font-family: Alike, Georgia, Times, serif;
    font-size: 22px;
    i {
      display: inline-block;
      margin-right: 20px;
    }
    color: @blue-light;
    a {
      color: @blue-light;
    }
    a:hover {
      color: white;
    }
  }
}

.happeningsHome {
  background: @green-light!important;
  h2 {
    color: @blue;
    margin-bottom: 0px;
    margin-left: 5px;
  }

  h3 {
    color: @blue;
    margin-bottom: 0px;
  }
  a {
    color: @grey;
    text-decoration: underline;
  }
  p {
    margin: 0 auto 10px auto;
  }
}

div.happMeta {
  background: #f4f4f4;
  padding: 15px;
  border-bottom: 1px solid @grey;
  p {
    font-size: 0.9rem;
    span {
      font-weight: bold;
      display: inline-block;
      width: 100px;
    }
  }
}

#healow-root {
  img {
    float: left;
  }
}

div.notice {
  padding: 8px;
  border: 2px solid #f7deae;
  background: #fdf6c2;
  i {
    display: inline-block;
    margin: 0 15px 10px 0px;
  }
}

#loginReg {
  display: none;
}

#breadcrumbs {
  text-transform: uppercase;
  font-size: 13px;
  padding-top: 20px;
  line-height: 1rem;
  span {
    display: inline-block;
    margin: 0 20px;
  }
  span:first-child {
    margin-left: 0px;
  }
  .breadcrumb_last {
    color: @orange;
  }
}

#homeBanner {
  padding: 10px;
  background: #ffffff;
  margin: 0 0 25px 0;

  .nivoSlider {
    height: 406px;
  }
  .nivo-caption {
    height: 343px;
  }
}

.homeIntro {
  h2 {
    color: @grey;
    line-height: 1.3em;
  }
}

#insideBanner {
  padding: 20px;
  width: 100%;
  background: #ffffff;
  margin: 25px 0 0 0;

  &.page-grid-banner {
    margin: 25px auto 25px;
  }

  #headerSlide {
    position: relative;

    img {
      position: relative;
      width: 100%;
    }
    .headerText {
      position: absolute;
      top: 2%;
      left: 25px;
      width: 50%;
      h2 {
        color: @blue;
        font-size: 36px;
      }
    }
    .headerStrapline {
      position: relative;
      bottom: 0px;
      height: 45px;
      margin-top: -3px;
      padding: 10px 0 10px 30px;
      background: @green-dark;
      h3 {
        color: #ffffff;
        padding: 0 10px 5px 0;
        margin-top: 0px;
        font-family: Lato, Arial, Helvetica, sans-serif;
      }
    }
  }
}

#sidebar {
  h2,
  h3,
  h4 {
    color: @grey;
  }
  padding-left: 40px;
  div.gallThumb {
    background: @green-dark;
    text-align: center;
    a {
      color: #ffffff;
      display: block;
      padding: 5px 0 10px 0;
    }
  }
  #galleryImages {
    display: none;
  }

  .youtube-player {
    iframe {
      width: 100%;
      min-height: 100%;
    }
  }
}

.embed-container {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  padding-top: 25px;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.youtube-container {
  display: block;
  margin: 20px auto;
  width: 100%;
  max-width: 600px;
}
.youtube-player {
  display: block;
  width: 100%; /* assuming that the video has a 16:9 ratio */
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: hand;
  cursor: pointer;
  display: block;
}
img.youtube-thumb {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 140px;
  height: auto;
}
div.play-button {
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: 36px;
  position: absolute;
  background: url("https://i.imgur.com/TxzC70f.png") no-repeat;
}

@-moz-document url-prefix() {
  /* Styles to be executed on firefox */

  img.youtube-thumb {
    top: 70px;
  }
}

ul#child_nav {
  padding-left: 0px;
  margin-left: -20px;
  li {
    list-style-type: none;
    font-size: 1rem;
    line-height: 1.6rem;
  }
  a {
    padding: 0 0 4px 20px;
    color: #333333;
    display: block;
    border-bottom: 1px solid transparent;
  }

  li.current_page_item a,
  a:hover {
    border-bottom: 1px solid @orange;
    color: #fff;
    background: @grey;
  }
}

ul#child_nav.plain_child_nav {
  margin-left: 0px;
  li {
    a {
      color: @blue;
      border-bottom: none;
      padding: 0;
    }
    a:hover {
      background: inherit;
      color: @blue;
    }
  }
}

.sectionHead.snug {
  margin: 0px;
}

table.contentTable {
  width: 100%;
  thead {
    tr {
      background: @blue-light;
    }
  }

  th,
  td {
    text-align: left;
    font-size: 13px;
  }

  td {
    border-bottom: 1px solid #cccccc;
    background: #ffffff;
    padding: 10px 5px;
  }

  th {
    border-bottom: 1px solid #333333;
    padding: 5px;
  }

  tbody {
    tr:hover td {
      background: @blue-light;
    }
  }
}

#opportunity {
  div {
    padding: 15px;
  }
  div.oppLogo {
    padding: 0px;
    text-align: center;
    width: 100%;
    border-bottom: 5px solid @blue;
  }

  div#oppDeets {
    max-width: 800px;
    margin: 0 auto;
  }

  div.listingDetails {
    padding: 0 0 15px 0;
    margin-bottom: 10px;
    p {
      margin: 0;
      line-height: 1rem;
    }
    div {
      padding: 5px;
    }
    .ldLeft {
      float: left;
      width: 200px;
      font-weight: bold;
      clear: both;
    }

    .ldRight {
      float: left;
      width: 400px;
    }
  }
  h2 {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  span#printPage {
    display: block;
    font-size: 1rem;
    i {
      display: inline-block;
      margin-right: 10px;
    }
    a {
      color: @blue;
    }
    a:hover {
      color: @purple;
    }
  }

  .print {
    display: none;
  }
  .desktop {
    display: block;
  }
  hr,
  .clear {
    clear: both;
  }
}

/* employment application form */
.gform_wrapper {
  .application-form {
    .button.gform_next_button,
    .button.gform_previous_button {
      border: none;
      padding: 10px 60px;
      font-size: 1.2em;
      cursor: pointer;
      background: @green;
      .transition(0.3s);
    }

    .button.gform_next_button:hover {
      background: @green-dark;
    }

    .button.gform_previous_button {
      background: @blue;
    }

    .button.gform_previous_button:hover {
      background: @blue-light;
    }

    .top_label {
      input.medium {
        width: 80%;
      }
    }
  }
}

div#gform_confirmation_wrapper_14,
div#gform_confirmation_wrapper_18 {
  font-size: 16px;
  padding: 20px;
  background: @blue-light;
  border: 1px solid @blue;
  margin: 20px 0;
}

li.textBoxShort textarea {
  height: 80px !important;
}

div.thumbHolder,
img.alignright,
img.alignleft,
img.aligncenter,
img.alignnone {
  .drop-shadow(3px, 3px, 12px, 0.7);
  margin: 12px;
  float: right;
}

img.alignnone {
  float: none;
  margin: 12px 0;
}

img.alignright {
  margin: 12px 0px 12px 12px;
}

img.alignleft {
  margin: 12px 12px 12px 0px;
}

div.thumbHolder {
  margin: 35px 0 0 0;
  img {
    max-width: 280px;
    height: auto;
    float: right;
  }
}

#blockHolder {
  margin: 0;
  width: 100%;
  #blockSlider {
    padding: 0;
    li {
      list-style: none;
      margin: 0;
    }
    .half {
      min-height: 320px;
      text-align: center;
      padding: 20px;
      img {
        margin: 20px auto;
      }
    }
    .half.textHalf {
      text-align: left;
      padding-right: 50px;
      h2,
      p {
        color: #ffffff;
        padding: 0 0 0 20px;
        line-height: 1.8rem;
        font-size: 1.25rem;
        line-height: 2.5rem;
        a {
          color: #ffffff;
          display: inline-block;
          padding-bottom: 2px;
          border-bottom: 1px solid @blue-light;
        }
      }

      h2 a {
        border-bottom: 0px;
        padding-bottom: inherit;
        color: #ffffff;
      }

      h2 {
        font-size: 2rem;
        margin-top: 10px;
      }
    }
    .block_text {
      padding-bottom: 20px;
    }
  }
}

footer.brownBG {
  background: @grey;
  padding: 20px;
  h3,
  p {
    color: #ffffff;
    margin: 15px 0;
  }
  p {
    font-size: 1rem;
  }
  .addr {
    p {
      text-align: right;
      line-height: 25px;
    }
  }
}

footer.navFooter {
  background: @green-light;
  padding: 20px;
  ul.footerMenu {
    padding: 0px;
    li {
      list-style-type: none;
      margin: 5px 0;
      a {
        padding: 5px;
        display: block;
        color: @grey;
        border-bottom: 1px solid @green-light;
      }
      a:hover {
        background: @turquoise;
        color: white;
        border-bottom: 1px solid @grey;
      }
    }
  }
}

footer.lastFooter {
  text-align: right;
  background: white;
  padding: 20px 20px 40px 20px;
  h4 {
    font-size: 1rem;
    line-height: 1.6rem;
  }
}

/* contact form */

.wpcf7-form {
  label {
    display: none;
  }
  input {
    padding: 8px;
    border: 1px solid @orange;
    margin: 0 0 10px 0;
    width: 100%;
    text-align: left;
  }
  textarea {
    width: 100%;
    border: 1px solid @orange;
    height: 80px;
    text-align: left;
  }
  input:focus,
  textarea:focus {
    border-color: @blue;
  }

  .wpcf7-mail-sent-ok {
    border: 1px solid @orange;
    margin: 0;
    width: 100%;
    padding: 15px;
    color: #ffffff;
  }
  button {
    background: @orange;
    border: none;
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    cursor: pointer;
    color: #fff;
    text-align: left;
    font-size: 1rem;
  }
}

.gform_wrapper form#gform_2 {
  label {
    display: none;
  }
  .gform_footer {
    margin: 0;
    padding: 0;
  }
  input {
    padding: 8px;
    border: 1px solid @orange;
    margin: 0 0 10px 0;
    width: 100%;
    font-size: 0.7rem;
  }
  input:focus,
  textarea:focus {
    border-color: @blue;
  }

  input[type="submit"] {
    background: @orange!important;
    border: none;
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    cursor: pointer;
    color: #fff;
    text-align: left;
    font-size: 1rem;
  }
}

#gform_confirmation_message_2 {
  border: 1px solid @orange;
  margin: 0;
  width: 100%;
  padding: 15px;
  color: #ffffff;
}

.physician {
  padding-bottom: 10px;
  margin: 10px 0;
  border-bottom: 1px solid @orange;
  .p_image {
    float: left;
    width: 25%;
    img {
      .drop-shadow(3px, 3px, 12px, 0.7);
      margin: 0 12px 0 0;
    }
  }
  .p_bio {
    float: left;
    width: 70%;
    margin-left: 5%;
    h3 {
      margin-top: 0px;
    }
  }
}

h2 .download {
  font-size: 12px;
}
/* GRID
-------------------------------------------------------------------------------*/
.full {
  width: 100%;
}
.half {
  width: 50%;
  float: left;
}
.third {
  width: 33%;
  float: left;
}
.thirdish {
  width: 34%;
}
.two_thirds {
  width: 66%;
}
.noMarg {
  margin: 0 !important;
}

section.content,
section.body_content {
  margin: 0 auto;
  text-align: left;
  max-width: 1240px;
}

section.content.fatBum,
section.body_content.fatBum {
  margin-bottom: 15px;
}

section.body_content {
  background: transparent url(_/images/white_transp.png) 0 0 repeat;
  margin-top: 0px;
  padding: 0 20px 40px 50px;
  ul {
    padding-left: 20px;
    li {
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }
  div.contentCol {
    img {
      width: 50%;
      height: auto;
    }
  }
}

.sb-menu {
  padding: 10px;
  h3 {
    color: @blue-light;
    margin-top: 0.25rem;
  }
  ul {
    padding-left: 0;

    li {
      display: block;
      a {
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid @blue;
        padding: 15px 5px;
        color: @blue-light;
      }
      a:hover,
      a:active {
        background: @blue-light;
        color: @blue;
      }
    }
  }
}

div.boxzilla-content {
  padding: 0 20px 20px;
  p,
  h2 {
    color: #ffffff;
  }
  h2 {
    font-size: 1.8rem;
  }
  p {
    line-height: 2.2rem;
    font-size: 1rem;
  }
  a {
    display: inline-block;
    margin-top: 30px;
    background: @orange;
    .transition(0.3s);
    padding: 12px 40px;
    color: #ffffff;
  }
  a:hover {
    background: @blue-light;
    color: @blue;
  }
}

#gform_16,
#gform_18 {
  input[type="submit"],
  input[type="button"] {
    padding: 12px 40px;
    cursor: pointer;
    background: @orange;
    font-size: 1.1rem;
    border: none;
    .transition(0.3s);
  }
  input[type="submit"]:hover,
  input[type="button"]:hover {
    background: @blue;
  }
}

#gform_18 {
  input[type="button"].gform_previous_button {
    background: @blue!important;
    color: #ffffff !important;
  }
}

#gform_confirmation_message_16 {
  font-size: 1.1rem;
  line-height: 1.8rem;
}

section.body_content.full_width {
  padding: 0px;
}

section.body_content.inside_width {
  padding: 0 40px 40px 40px;
}

.span_full {
  .column(12);
}

.span_five_sixth {
  .column(10);
}

.span_three_quarters {
  .column(9);
}

.span_two_thirds {
  .column(8);
}

.span_half {
  .column(6);
}

.span_third {
  .column(4);
}

.span_quarter {
  .column(3);
}

.span_sixth {
  .column(2);
}

.news-loop {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 56px 76px !important;
  margin-bottom: 25px !important;

  .heading {
    margin-top: 0;
  }
}

.news-grid,
.staff-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 16px;
  row-gap: 54px;
}

.staff-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.card-item {
  .image-wrap {
    display: block;
    position: relative;
    overflow: hidden;
  }
  .image-wrap:not(.auto) img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &.news {
    .image-wrap {
      height: 350px;
    }
    .title {
      font-size: 18px;
      margin-bottom: 4px;
    }

    .date,
    .categories {
      font-size: 16px;
      color: #333333;
      margin-bottom: 5px;
    }
    .date {
      margin-bottom: 17px;
    }
    .excerpt {
      font-size: 16px;
      color: #333333;
      margin-bottom: 17px;
      line-height: 1.2;
    }
  }
}
.page-template-page-news {
  .card-item {
    &.news {
      .image-wrap {
        height: 235px;
      }
    }
  }
}
.btn-wrap {
  .btn-link {
    display: inline-block;
    font-size: 16px;
    color: #214498;
  }
}

.facetwp-facet.facetwp-type-pager {
  margin-top: 54px;
  margin-bottom: 0;

  .facetwp-pager {
    text-align: center;
    font-size: 16px;
  }
}

.news-filters {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 16px;
  row-gap: 0;

  .facetwp-facet {
    margin-bottom: 11px;
    position: relative;
  }

  select,
  input[type="text"] {
    background-color: #fff;
    padding: 12px 22px;
    border: 1px solid #707070;
    width: 100%;
    font-size: 13px;
    color: #214498;
    line-height: 1;
  }

  input[type="button"] {
    background: #fff url("_/images/magnifying-glass.svg");
    background-size: 20px auto;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    border: 0 none;
    outline: none;
    overflow: hidden;
    text-indent: -999px;
  }
}

.facetwp-template {
  opacity: 1;
  transition: opacity 0.3s;

  &.is-loading {
    opacity: 0.4;
  }
}

article {
  .post-thumbnail {
    padding: 10px 10px;
    background-color: #fff;
    margin-bottom: 28px;

    .inner {
      position: relative;
      overflow: hidden;
      height: 345px;
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .breadcrumbs {
    background-color: #fff;
    padding: 14px 52px 14px 76px;

    #breadcrumbs {
      padding: 0 0;
      margin: 0 0;
    }
  }

  .post-body {
    padding: 47px 52px 47px 76px;
    margin-bottom: 28px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    &.physycian {
      .content {
        flex-basis: 45%;
        width: 45%;
      }

      .sidebar.thumb-area {
        flex-basis: 20%;
        width: 20%;
      }
    }

    .content {
      flex-basis: 51%;
      width: 51%;
    }

    .entry-title {
      font-size: 26px;
      color: #214498;
      margin-top: 0;
      margin-bottom: 16px;
    }
    .date,
    .categories {
      font-size: 13px;
      color: #333333;
      margin-bottom: 5px;
    }
    .date {
      margin-bottom: 25px;
    }

    .entry-content {
      p,
      li {
        font-size: 16px;
        color: #333333;
      }
      p,
      ul,
      ol {
        margin-bottom: 30px;
      }
    }

    .sidebar {
      flex-basis: 30%;
      width: 30%;

      img {
        width: 100%;
        height: auto;
      }
      .widget {
        margin-bottom: 38px;

        &:last-child {
          margin-bottom: 0;
        }
      }
      .widget-title {
        margin: 0 0 22px;
        font-size: 22px;
        color: #214498;
      }
      .post-list {
        list-style-type: none;
        padding-left: 0;

        li {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }
        a {
          font-family: Alike, Georgia, Times, serif;
          font-size: 18px;
          color: #333333;
        }
      }

      form {
        position: relative;

        input[type="search"] {
          background-color: #fff;
          padding: 12px 22px;
          border: 1px solid #707070;
          width: 100%;
          font-size: 13px;
          color: #214498;
          line-height: 1;
        }

        input[type="submit"] {
          background: #fff url("_/images/magnifying-glass.svg");
          background-size: 20px auto;
          background-position: center;
          background-repeat: no-repeat;
          position: absolute;
          top: 50%;
          right: 15px;
          margin-top: -15px;
          width: 30px;
          height: 30px;
          border: 0 none;
          outline: none;
          overflow: hidden;
          text-indent: -999px;
        }
      }
    }
  }
}

/* Print styles!
-------------------------------------------------------------------------------*/
@media print {
  .print {
    display: block;
  }
}

/* Media queries!
-------------------------------------------------------------------------------*/
@media screen and (max-width: 1024px) {
  nav#topNav {
    #header_nav {
      li {
        margin: 0 10px;
      }
    }
  }

  nav#mainNav {
    margin-bottom: 25px;
    #main_nav {
      li {
        margin-right: 10px;
        font-size: 12px;
        a {
          height: 50px;
          padding: 0 11px;
        }
      }
    }
  }
}

@media screen and (max-width: 842px) {
  nav#mainNav {
    display: none;
  }
  nav#topNav {
    height: 50px;
    ul {
      display: none;
    }
  }

  .nivoSlider {
    height: 100% !important;
  }

  .nivo-caption {
    top: 0px;
    h2 {
      font-size: 30px;
      span.subHeading {
        font-size: 20px;
      }
    }
  }

  div.thumbHolder {
    img {
      max-width: 100%;
    }
  }

  .servicesHead {
    font-size: 16px !important;
    line-height: 2rem;
  }

  #insideBanner {
    #headerSlide {
      .headerText {
        width: 60%;
        h2 {
          font-size: 26px;
        }
      }
    }
  }

  .news-grid,
  .staff-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  article {
    .breadcrumbs {
      padding: 14px 47px;
    }
    .post-body {
      padding: 47px 47px;

      .content {
        flex-basis: 60%;
        width: 60%;
      }

      &.physycian {
        .content {
          flex-basis: 60%;
          width: 60%;
        }

        .sidebar.thumb-area {
          flex-basis: 35%;
          width: 35%;
        }

        .sidebar:not(.thumb-area) {
          margin-top: 60px;
          flex-basis: 100%;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  /* SMARTPHONE GRID
-------------------------------------------------------------------------------*/
  #insideBanner {
    display: none;
  }

  .button.expanded {
    width: 100%;
  }

  footer a[href^="tel"] {
    color: #ffffff !important;
  }

  header {
    #headerContact {
      text-align: center;
    }
  }

  .nivo-caption {
    h2 {
      font-size: 20px;
      padding-top: 10px;
      line-height: inherit;
      span.subHeading {
        font-size: 16px;
      }
    }
    p.slideAttr {
      position: relative;
      font-size: 14px;
      height: 30px;
      color: @grey;
      background: none;
      padding-left: 35px;
      a {
        color: @blue;
      }
      span {
        font-size: 14px;
        margin-left: 0px;
        display: block;
        color: @blue;
      }
    }
  }

  .contentBlock.half {
    width: 100%;
  }

  #insideBanner {
    #headerSlide {
      .headerText {
        width: 80%;
        top: 0;
        left: 10px;
        h2 {
          font-size: 15px;
          line-height: inherit;
        }
      }
      .headerStrapline {
        height: 30px;
        padding-left: 10px;
        h3 {
          font-size: 14px;
        }
      }
    }
  }

  #sidebar {
    padding-left: 0px;

    ul#child_nav {
      li {
        a {
          border-bottom: 1px solid @grey;
          padding: 10px 0;
          margin-left: 20px;
          color: @purple;
        }
        a:hover,
        a:active {
          padding-left: 10px;
          color: #ffffff;
        }
      }
    }
  }

  #breadcrumbs {
    padding: 10px 5px;
    background: @blue-light;
    span {
      display: inline-block;
      margin: 0;
    }
  }

  .span_full,
  .span_five_sixth,
  .span_three_quarters,
  .span_two_thirds,
  .span_half {
    .column(12);
  }

  .span_third {
    .column(12);
  }
  .span_quarter {
    .column(6);
  }

  .span_quarter.homeHappening {
    .column(12);
  }

  .span_sixth {
    .column(3);
  }

  .news-loop {
    padding: 50px 20px !important;
  }

  .news-grid,
  .staff-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  article {
    .breadcrumbs {
      padding: 14px 20px;

      #breadcrumbs {
        background: transparent none !important;
      }
    }
    .post-body {
      padding: 47px 20px;

      .content,
      .sidebar {
        flex-basis: 100%;
        width: 100%;
      }

      &.physycian {
        .content {
          flex-basis: 100%;
          width: 100%;
        }

        .sidebar.thumb-area {
          flex-basis: 100%;
          width: 100%;
          margin-bottom: 40px;
        }

        .sidebar:not(.thumb-area) {
          margin-top: 40px;
          flex-basis: 100%;
          width: 100%;
        }
      }
    }
  }
}
