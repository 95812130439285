@grey: #333333;

html, body {
	width: 100%;
	overflow-x: hidden; /* Stops horizontal scrolling. */
	margin:0;
}

/* A Linux- and Windows-friendly sans-serif font stack: http://prospects.mhurrell.co.uk/post/updating-the-helvetica-font-stack */
body {font: 100%/1.5 Lato, Helvetica, Arial, Verdana, serif;}

/* Using local fonts? Check out Font Squirrel's webfont generator: http://www.fontsquirrel.com/tools/webfont-generator */

/* We like off-black for text. */
body, select, input, textarea {color: #333;}

* {
	outline: none;
	position: relative;
}


/* Custom text-selection colors (remove any text shadows: http://twitter.com/miketaylr/status/12228805301) */
::-moz-selection{background: @grey; color: #fff; text-shadow: none;}
::selection {background: @grey; color: #fff; text-shadow: none;} 

/*	j.mp/webkit-tap-highlight-color */
a:link {-webkit-tap-highlight-color: @grey;} 

ins {background-color: @grey; color: #fff; text-decoration: none;}
mark {background-color: @grey; color: #fff; font-style: italic; font-weight: bold;}

/* Mozilla dosen't style place holders by default */
input:-moz-placeholder { color:#333333; }
textarea:-moz-placeholder { color:#333333; }

html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden; /* Stops horizontal scrolling. */  
  min-height: 100%;
}

body {
  overflow-x: hidden; /* Stops horizontal scrolling. */  
}

*, *:before, *:after {
  -moz-box-sizing: 		border-box;
  -webkit-box-sizing: 	border-box;
  box-sizing: 			border-box;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
 
.clearfix:after {
  clear: both;
}
 
.clearfix {
  *zoom: 1;
}

/* Typography
   ========================================================================== */

h1, h2 {
  font-size: 1.8rem;
  line-height: 1.6em;
  margin-bottom: 1.4rem;
}

h2 {
	font-size: 1.6rem;
}

h3 {
  font-size: 1.2rem;
  margin-bottom: .5rem;
}

p {
  color: #333;
  margin: 10px auto;
  line-height: 1.6rem;
  font-size: 1rem;
}

blockquote { margin: 0; }

blockquote p {
  color: #666;
  font-style: italic;
  margin-bottom: 1.5rem;
}

/* Lists
   ========================================================================== */

ul {
  margin: 0;
  text-align: left;
}

/* Links
   ========================================================================== */

a {
  text-decoration: none;
  .transition(.4s);
}